
























import { Component, Prop, Vue } from 'vue-property-decorator';
//@ts-ignore
import Papa from 'papaparse';
@Component({})
export default class CSVImporter extends Vue {
  @Prop({ required: false, default: false }) private readonly undetermined!: boolean | number;
  @Prop({ required: false, default: false }) private readonly hasPreview!: boolean | number;

  private csv = [];
  private parsedCSV = [];
  private headers = ['lon', 'lat', 'name'];
  private hasHeader = true;
  private fileHandler = null;
  private onDrop(event: any) {
    this.fileHandler = event.dataTransfer.files[0];
    this.loadFile();
  }

  private loadFile() {
    //@ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this: any = this;

    this.readFile((output: any) => {
      if (output) {
        _this.csv = Papa.parse(output, { skipEmptyLines: true }).data;
        const response = _this.csv.map((row: any[]) => {
          if (row.length > 2) {
            return {
              lon: row[1],
              lat: row[0],
              name: row[2]
            };
          }
        });
        _this.$emit('input', response);
      }
      if (this.hasHeader) _this.csv.shift();

      this.fileHandler = null;
    });
  }
  private readFile(callback: Function) {
    //@ts-ignore
    const file = this.fileHandler ? this.fileHandler : this.$refs.csv.files[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsText(file, 'UTF-8');
      reader.onload = function(evt) {
        //@ts-ignore
        callback(evt.target.result);
      };
      reader.onerror = function() {};
    }
  }
}
