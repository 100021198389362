





















































































































































































































































































































































































































































































































































































































import numeral from 'numeral';
import { Prop, Component, Vue, Watch } from 'vue-property-decorator';
import Map from '../components/Map.vue';
import * as Sentry from '@sentry/vue';
import axios from 'axios';
import * as turf from '@turf/turf';
import { searchPlaces } from '@/helpers/mapbox';
import { startSearch } from '@/helpers/fuse';
import { getGeometry } from '@/helpers/turf';
import mapboxgl from 'mapbox-gl';
import { Place } from '@/types/mapbox-gl';
import { v4 as uuidv4 } from 'uuid';
import listSVG from '@/assets/img/list.svg';
import locationSVG from '@/assets/img/location.svg';
import calendarSVG from '@/assets/img/calendar.svg';
import TargetSVG from '@/assets/img/target.svg';
import LassoSVG from '@/assets/img/lasso.svg';
import CloseSVG from '@/assets/img/close.svg';
import TrashSVG from '@/assets/img/trash.svg';
import MobileBar from '@/components/MobileBar.vue';
import analytics from '@/analytics';
import ProgressBar from '@/components/ProgressBar.vue';
import { updateSession } from '@/helpers/session';
import CSVImporter from '@/components/CSVImporter.vue';
import { getPanels } from '@/helpers/api';
import dayjs from 'dayjs';

interface POI {
  id: string;
  source: any;
  img: string;
  position: string;
  address: string;
  availability: boolean;
  reach: number;
  price: number;
  group?: any;
  companyLogo: string;
}
@Component({
  components: {
    Map,
    MobileBar,
    calendarSVG,
    locationSVG,
    listSVG,
    ProgressBar,
    LassoSVG,
    CloseSVG,
    TrashSVG,
    CSVImporter,
    TargetSVG
  }
})
export default class CampaignPlanner extends Vue {
  @Prop({}) data!: any;
  private selectedPOI: null | POI = null;
  private showPickDate = true;
  private showSearch = true;
  private showPricePicker = false;
  private zoomLevel = 13;
  //@ts-ignore
  private numeral = numeral;
  private currentMobileView: 'panels' | 'map' = 'map';
  private search = '';
  private filters: any = {
    environments: [],
    fromAmenities: [],
    formats: [],
    priceRange: [],
    bboxes: [],
    csv: null
  };
  private searchedPOIS: any = [];
  private isDrawing = false;
  private selectDrawing = false;
  private markers: any[] = [];
  private panels = [];
  private range = {
    start: null,
    end: null
  };

  private imc = process.env.VUE_APP_BUILD === 'imediacenter';

  get minDate() {
    const day = dayjs().day(),
      hour = dayjs().hour();
    if (day === 0 || (day === 5 && hour >= 12) || day === 6)
      return dayjs()
        .day(day === 0 ? 2 : 9)
        .toDate();
    if (hour >= 12)
      return dayjs()
        .add(2, 'day')
        .toDate();
    return dayjs()
      .add(1, 'day')
      .toDate();
  }

  private resetPanels() {
    this.setPanels();
  }
  private updatePrice(event: any) {
    this.filters.priceRange = event;
    this.filters.priceRange = {
      min: event[0],
      max: event[1]
    };
  }
  private searchOptionsHandler: Array<any> = [];
  private map: mapboxgl.Map | null = null;

  private addAmenity() {
    this.filters.fromAmenities.push({
      amenities: [],
      distance: 200,
      customCSV: {
        name: '',
        session_id: this.$cookies.get('user_session'),
        data: []
      },
      _params: {
        hasPreview: false
      }
    });
    this.$forceUpdate();
  }

  private removeAmenity(index: number) {
    this.filters.fromAmenities.splice(index, 1);
    this.$forceUpdate();
  }

  private stopUpload() {
    this.$store.state.cancelTokenSource.cancel('Upload canceled');
    this.$store.state.progress = 0;
    this.$store.commit('removeMedia');
  }

  private togglePage() {
    if (this.currentMobileView === 'panels') {
      this.currentMobileView = 'map';
    } else {
      this.showPickDate = false;
      this.showSearch = false;
      this.currentMobileView = 'panels';
    }
  }

  private togglePickDate() {
    this.currentMobileView = 'map';
    this.showPickDate = !this.showPickDate;
  }

  private toggleSearch() {
    this.currentMobileView = 'map';
    this.showSearch = !this.showSearch;
  }

  private async searchPanels() {
    try {
      this.$wait.start('loading panels');
      const response = await axios.get('http://localhost:8107/getScreenByAmenity', {
        params: this.filters
      });
      this.panels = response.data;
      this.markers = response.data.map((p: any) => {
        return {
          id: uuidv4(),
          lng: p.long,
          lat: p.lat,
          data: {
            ...p,
            thumbnail: p.thumbnail
              ? `https://api.staging.vx.glooh.app/assets/${p.thumbnail.id}`
              : ''
          }
        };
      });
      this.$wait.end('loading panels');
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  private createScreenObject(marker: any) {
    const poi = marker.data;
    let companyLogo = '';
    if (poi.provider === 'JCD') companyLogo = 'https://www.glooh.media/screens/jcd.png';
    else if (poi.provider === 'Publifer')
      companyLogo = 'https://www.glooh.media/screens/publifer.png';
    else if (poi.provider === 'oxialive') companyLogo = '/assets/logo/oxialive_logo.png';
    else if (poi.provider === 'Glooh') companyLogo = 'https://www.glooh.media/screens/glooh.png';
    else if (poi.provider === 'imediacenter') companyLogo = '/assets/logo/imediacenter_logo.png';
    else if (poi.provider === 'SCREENINGMEDIA_BE') companyLogo = 'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/screening-media/images/logo-alt-blanc.svg';
    //else if (poi.provider === 'CLEAR_BE') companyLogo = 'https://upload.wikimedia.org/wikipedia/commons/1/1c/Clear_Channel_logo.svg';

    return {
      id: poi.id,
      source: poi,
      img: poi.thumbnail,
      position: poi.location_type,
      address: poi.address,
      availability: true,
      reach: parseFloat(poi.reach),
      price: parseFloat(poi.price),
      companyLogo
    };
  }

  private inPolygon(draw: any) {
    const draws = draw.getAll();
    this.filters.bboxes = draws.features.map((f: any) => f.geometry.coordinates[0]);
    this.isDrawing = false;
    const selectedScreens: any = [];
    this.$store.commit('addScreens', selectedScreens);
  }

  private deletePolygons(draw: any) {
    const selectedDrawsIds = draw.getSelectedIds();
    selectedDrawsIds.forEach((id: string) => {
      const temp = draw.get(id);
      const coord = temp.geometry.coordinates;
      const poly = turf.polygon(coord);
      this.markers.forEach(marker => {
        const point = turf.point([marker.lng, marker.lat]);
        const inPolygon = turf.booleanPointInPolygon(point, poly);
        const screenToRemove = this.createScreenObject(marker);
        if (!inPolygon) {
          const index = this.$store.state.screens.findIndex((screen: any) => {
            screen.id === screenToRemove.id;
          });
          this.removePOI(index);
        }
      });
      draw.delete(id);
    });
    this.selectPolygons(draw);
  }

  private selectPolygons(draw: any) {
    const selectedDrawsIds = draw.getSelectedIds();
    !selectedDrawsIds.length ? (this.selectDrawing = false) : (this.selectDrawing = true);
  }

  private get initialPosition() {
    const initialPosition = this.$store.state.config?.broadcast?.initialPosition
      ? this.$store.state.config?.broadcast?.initialPosition
      : [5.580534, 50.632403];
    return this.$store.state.selectedCity ? this.getCoordinates : initialPosition;
  }
  private get getCoordinates() {
    return this.$store.state.selectedCity.geometry.coordinates;
  }
  private get getGroup() {
    if (this.selectedPOI?.source?.group && this.selectedPOI?.source?.group.length) {
      return this.selectedPOI?.source.group[0].panels_groups_id;
    }
    return null;
  }

  private get selectionsCount() {
    return this.$store.getters['getScreensCount'];
  }
  private get isAlreadySelected() {
    return this.$store.state.screens.some((s: any) => s.id === this.selectedPOI?.id);
  }

  private async toggleSelected() {
    const indexSelected = this.$store.state.screens.findIndex(
      (poi: POI) => poi.id === this.selectedPOI?.id
    );
    if (indexSelected !== -1) this.removePOI(indexSelected);
    else {
      this.$store.commit('addScreen', this.selectedPOI as POI);
      this.updateBookingSession();
    }
    analytics.track('choosedPanel', {
      id: this.selectedPOI?.id,
      panelAddress: this.selectedPOI?.position
    });

    this.selectedPOI = null;
    this.$forceUpdate();
  }

  private async searchAddress(search: string) {
    if (search && search.length) {
      //@ts-ignore
      this.searchOptionsHandler = await searchPlaces({
        search,
        types: ['postcode', 'locality', 'place'],
        language: 'fr',
        country: ['be', 'fr']
      });

      if (this.$store.state.config.hidePlaceSearch) this.searchOptionsHandler = [];

      const temp = await startSearch(search);
      this.searchedPOIS = getGeometry(temp);
      if (this.searchedPOIS.length) this.searchOptionsHandler.unshift(...this.searchedPOIS);
    }
  }

  private background(img: string | null) {
    return img
      ? {
          'background-image': `url('${img}')`,
          'background-size': 'cover',
          'background-position': 'center'
        }
      : '';
  }
  private updateValue(data: Place) {
    if (!data) return;
    this.search = data.place_name;
    this.$store.commit('setSelectedCity', data);
  }

  private moveMap(place: Place) {
    this.map?.flyTo({
      center: place.geometry.coordinates,
      zoom: place.zoom ? place.zoom : 13,
      pitch: place.pitch ? place.pitch : 60,
      bearing: place.bearing ? place.bearing : 10
    });
    this.searchOptionsHandler = [];
    this.search = place.place_name;
    updateSession({
      map: { search: this.search, params: place }
    });
  }

  private zoom(zoom: number) {
    const range = {
      min: 5,
      max: 15
    };
    if (zoom > range.max) this.zoomLevel = range.max;
    if (zoom < range.min) this.zoomLevel = range.min;
    else this.zoomLevel = zoom;
    setTimeout(() => {
      if (this.map) this.map.setZoom(this.zoomLevel, { duration: 1000 });
    }, 10);
  }

  private removePOI(index: number, updateBookingSession = true) {
    this.$store.commit('removeScreen', index);
    if (updateBookingSession) this.updateBookingSession();
  }

  private updateCard(data: { data: any; element: any }) {
    this.selectedPOI = this.createScreenObject(data);
    this.$forceUpdate();
  }

  private updateBookingSession() {
    updateSession({
      booking: {
        panels: this.$store.state.screens,
        creativeId: this.$store.state.creative,
        dates: { start: this.$store.state.fromDate, end: this.$store.state.toDate }
      }
    });
  }

  @Watch('range', { deep: true })
  private handleFromDate(dates: { start: Date; end: Date }) {
    this.$store.commit('setToDate', dates.end);
    this.$store.commit('setFromDate', dates.start);
    this.updateBookingSession();
  }

  private hideCard() {
    this.selectedPOI = null;
  }

  private created() {
    updateSession({
      currentPage: this.$route.name
    });
    if (this.$store.state.place) {
      this.updateValue(this.$store.state.place);
    }
  }
  private downloadCSV() {
    const data = this.panels.map((poi: any) => {
      return [poi.lat ?? '', poi.long ?? '', poi.provider ?? '', poi.status ?? ''];
    });

    // Building the CSV from the Data two-dimensional array
    // Each column is separated by ";" and new line "\n" for next row
    let csvContent = '';
    data.forEach((infoArray: Array<any>, index: number) => {
      const dataString = infoArray.join(';');
      csvContent += index < data.length ? dataString + '\n' : dataString;
    });

    // The download function takes a CSV string, the filename and mimeType as parameters
    // Scroll/look down at the bottom of this snippet to see how download is called
    const download = (content: string, fileName: string, mimeType: string) => {
      const a = document.createElement('a');
      mimeType = mimeType || 'application/octet-stream';

      if (navigator.msSaveBlob) {
        // IE10
        navigator.msSaveBlob(
          new Blob([content], {
            type: mimeType
          }),
          fileName
        );
      } else if (URL && 'download' in a) {
        //html5 A[download]
        a.href = URL.createObjectURL(
          new Blob([content], {
            type: mimeType
          })
        );
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
      }
    };
    download(csvContent, 'dowload.csv', 'text/csv;encoding:utf-8');
  }

  private async setPanels() {
    this.panels = await getPanels();
    if (this.$store.state.selectedCity) this.search = this.$store.state.selectedCity.place_name;

    //@ts-ignore
    this.markers = this.panels.map((p: any) => {
      return {
        id: uuidv4(),
        lng: p.long,
        lat: p.lat,
        data: {
          ...p,
          thumbnail: p.thumbnail ? `https://api.staging.vx.glooh.app/assets/${p.thumbnail.id}` : ''
        }
      };
    });
  }

  private mapLoaded(event: any) {
    this.map = event;
    if (this.$store.state.place) this.moveMap(this.$store.state.place);
  }

  private async mounted() {
    this.setPanels();
    this.range = {
      start: this.$store.state.fromDate,
      end: this.$store.state.toDate
    };
  }
}
