


































































import { Prop, Component, Vue, Watch } from 'vue-property-decorator';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
//@ts-ignore
import { IndoorMap } from 'mapbox-gl-indoor';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import maisonNeuve from '@/map/maison-neuve.json';
import noyellesGodault from '@/map/noyelles-godault.json';
import gareDeLEst from '@/map/gare-de-l-est.json';
import { point } from '@turf/helpers';

import IndoorMapStyle from  '@/map/indoor-style.json';

mapboxgl.accessToken =
  'pk.eyJ1IjoicmFuc2V1ciIsImEiOiJja2xhb2E3NmMzbTd1MnJxbzZkdnUzcTNyIn0.DeAM00VdCGe-eLCVhlB8mA';

type Marker = {
  lat: number;
  lng: number;
  data: any;
};

@Component({})
export default class MapView extends Vue {
  @Prop({ default: () => [] }) private markers!: Array<Marker>;
  @Prop({ default: () => [5.580534, 50.632403] }) private initialPosition!: [number, number];
  private mapLoaded = false;
  private zoom = 13;
  private mapInstance: mapboxgl.Map | null = null;
  private localMarkers: mapboxgl.Marker[] = [];
  private draw: any = null;

  private setMarkers() {
    if (!this.mapInstance || !this.mapLoaded) return;
    else {
      const points: any = {
        portrait: {
          type: 'FeatureCollection',
          features: []
        },
        landscape: {
          type: 'FeatureCollection',
          features: []
        },
        landscapeSelected: {
          type: 'FeatureCollection',
          features: []
        },
        portraitSelected: {
          type: 'FeatureCollection',
          features: []
        }
      };

      this.markers.forEach(m => {
        let arrayToPush = this.$store.state.screens.find((s: any) => s.id === m.data.id)
          ? points.portraitSelected.features
          : points.portrait.features;
        if (m.data.orientation === 'landscape') {
          arrayToPush = this.$store.state.screens.find((s: any) => s.id === m.data.id)
            ? points.landscapeSelected.features
            : points.landscape.features;
        }
        arrayToPush.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [m.lng, m.lat]
          },
          properties: m
        });
      });

      if (this.mapInstance.getSource('portraits')) {
        //@ts-ignore
        this.mapInstance.getSource('portraits').setData({
          //@ts-ignore
          ...points.portrait
        });
      } else {
        this.mapInstance.addSource('portraits', {
          type: 'geojson',
          //@ts-ignore
          data: points.portrait,
          generateId: true
        });
      }

      if (this.mapInstance.getSource('landscapes')) {
        //@ts-ignore
        this.mapInstance.getSource('landscapes').setData({
          //@ts-ignore
          ...points.landscape
        });
      } else {
        this.mapInstance.addSource('landscapes', {
          type: 'geojson',
          //@ts-ignore
          data: points.landscape,
          generateId: true
        });
      }
      if (this.mapInstance.getSource('selected_landscapes')) {
        //@ts-ignore
        this.mapInstance.getSource('selected_landscapes').setData({
          //@ts-ignore
          ...points.landscapeSelected
        });
      } else {
        this.mapInstance.addSource('selected_landscapes', {
          type: 'geojson',
          //@ts-ignore
          data: points.landscapeSelected,
          generateId: true
        });
      }
      if (this.mapInstance.getSource('selected_portraits')) {
        //@ts-ignore
        this.mapInstance.getSource('selected_portraits').setData({
          //@ts-ignore
          ...points.portraitSelected
        });
      } else {
        this.mapInstance.addSource('selected_portraits', {
          type: 'geojson',
          //@ts-ignore
          data: points.portraitSelected,
          generateId: true
        });
      }

      if (!this.mapInstance!.getLayer('portraits')) {
        this.mapInstance!.addLayer({
          id: 'portraits',
          type: 'symbol',
          source: 'portraits',
          layout: {
            'icon-image': 'portrait', // reference the image
            'icon-size': 0.15,
            "icon-allow-overlap": true,
          }
        });
      }

      if (!this.mapInstance!.getLayer('landscapes')) {
        this.mapInstance!.addLayer({
          id: 'landscapes',
          type: 'symbol',
          source: 'landscapes',
          layout: {
            'icon-image': 'landscape', // reference the image
            'icon-size': 0.15,
            "icon-allow-overlap": true,
          }
        });
      }
      if (!this.mapInstance!.getLayer('selected_landscapes')) {
        this.mapInstance!.addLayer({
          id: 'selected_landscapes',
          type: 'symbol',
          source: 'selected_landscapes',
          layout: {
            'icon-image': 'selected_landscape', // reference the image
            'icon-size': 0.15,
            "icon-allow-overlap": true,
          }
        });
      }
      if (!this.mapInstance!.getLayer('selected_portraits')) {
        this.mapInstance!.addLayer({
          id: 'selected_portraits',
          type: 'symbol',
          source: 'selected_portraits',
          layout: {
            'icon-image': 'selected_portrait', // reference the image
            'icon-size': 0.15,
            "icon-allow-overlap": true,
          }
        });
      }

      this.mapLoaded = true;

      this.mapInstance.on('mouseenter', 'portraits', e => {
        this.mapInstance!.getCanvas().style.cursor = 'pointer';
      });

      this.mapInstance.on('mouseleave', 'portraits', () => {
        this.mapInstance!.getCanvas().style.cursor = 'auto';
      });
      this.mapInstance!.on('click', 'portraits', (e: any) => {
        this.$emit('markerClicked', {
          marker: e.features[0].properties,
          element: e,
          data: JSON.parse(e.features[0].properties.data)
        });
      });

      this.mapInstance.on('mouseenter', 'selected_portraits', e => {
        this.mapInstance!.getCanvas().style.cursor = 'pointer';
      });

      this.mapInstance.on('mouseleave', 'selected_portraits', () => {
        this.mapInstance!.getCanvas().style.cursor = 'auto';
      });
      this.mapInstance!.on('click', 'selected_portraits', (e: any) => {
        this.$emit('markerClicked', {
          marker: e.features[0].properties,
          element: e,
          data: JSON.parse(e.features[0].properties.data)
        });
      });

      this.mapInstance.on('mouseenter', 'landscapes', e => {
        this.mapInstance!.getCanvas().style.cursor = 'pointer';
      });

      this.mapInstance.on('mouseleave', 'landscapes', () => {
        this.mapInstance!.getCanvas().style.cursor = 'auto';
      });
      this.mapInstance!.on('click', 'landscapes', (e: any) => {
        this.$emit('markerClicked', {
          marker: e.features[0].properties,
          element: e,
          data: JSON.parse(e.features[0].properties.data)
        });
      });

      this.mapInstance.on('mouseenter', 'selected_landscapes', e => {
        this.mapInstance!.getCanvas().style.cursor = 'pointer';
      });

      this.mapInstance.on('mouseleave', 'selected_landscapes', () => {
        this.mapInstance!.getCanvas().style.cursor = 'auto';
      });
      this.mapInstance!.on('click', 'selected_landscapes', (e: any) => {
        this.$emit('markerClicked', {
          marker: e.features[0].properties,
          element: e,
          data: JSON.parse(e.features[0].properties.data)
        });
      });
    }

    /*
    this.localMarkers.forEach((m: any) => m.remove());
    this.markers.forEach((marker: any) => {
      const element = document.createElement('div');
      element.className =
        'marker border border-4 rounded-full transition-opacity duration-300 w-6 h-6 cursor-pointer';
      if (this.$store.state.screens.some((screen: any) => screen.id === marker.data.id)) {
        element.className += ' bg-primary';
      } else {
        element.className += ' bg-gray-400';
      }
      this.$forceUpdate();
      const markerInstance = new mapboxgl.Marker({
        element
      });
      markerInstance.setLngLat([marker.lng, marker.lat]);
      markerInstance.addTo(this.mapInstance as any);
      this.localMarkers.push(markerInstance);
      element.setAttribute('id', marker.id);
      element.addEventListener('click', e => {
        e.stopPropagation();
        this.removeSelected();
        element.classList.add('marker-selected');
        this.$emit('markerClicked', { marker, element, data: marker });
      });
    });
  */
  }

  async loadImage(map: mapboxgl.Map, src: any) {
    return await new Promise((res, err) => {
      map.loadImage(src, function(error: any, image: any) {
        if (error) return err(error);
        res(image);
      });
    });
  }

  @Watch('markers')
  private handleMarkers() {
    this.setMarkers();
  }

  @Watch('$store.state.screens')
  private handleMarkersScreens() {
    this.setMarkers();
  }

  private removeSelected() {
    Array.from(document.getElementsByClassName('marker-selected')).forEach((m: any) => {
      m.classList.remove('marker-selected');
    });
  }

  private mounted() {
    this.mapInstance = new mapboxgl.Map({
      container: this.$refs.mapRef as HTMLElement, // container ID
      style: 'mapbox://styles/mapbox/light-v10', // style URL
      center: this.initialPosition, // starting position [lng, lat]
      zoom: this.zoom // starting zoom
    });

    this.mapInstance.on('load', () => {
      this.loadImage(this.mapInstance!, `/img/portrait.png`).then(data => {
        this.mapInstance!.addImage('portrait', data as any);
      });
      this.loadImage(this.mapInstance!, `/img/landscape.png`).then(data => {
        this.mapInstance!.addImage('landscape', data as any);
      });
      this.loadImage(this.mapInstance!, `/img/added_portrait.png`).then(data => {
        this.mapInstance!.addImage('selected_portrait', data as any);
      });
      this.loadImage(this.mapInstance!, `/img/added_landscape.png`).then(data => {
        this.mapInstance!.addImage('selected_landscape', data as any);
      });

      this.mapInstance!.on('click', () => {
        this.removeSelected();
        this.$emit('mapClicked');
      });



      const indoorMaps = [];
      const layers = IndoorMapStyle;
      this.setMarkers();
      indoorMaps.push(IndoorMap.fromGeojson(noyellesGodault, { layers, beforeLayerId: 'portraits' }));
      indoorMaps.push(IndoorMap.fromGeojson(maisonNeuve, {  layers, beforeLayerId: 'portraits' }));
      indoorMaps.push(IndoorMap.fromGeojson(gareDeLEst, { layers, beforeLayerId: 'portraits' }));
      console.log(indoorMaps);

      indoorMaps.forEach(indoorMap => {
        //@ts-ignore
        this.mapInstance?.indoor.addMap(indoorMap);
      });

      /*this.draw = new MapboxDraw({
        displayControlsDefault: false
      });
      this.mapInstance!.addControl(this.draw, 'bottom-right');*/

      const drawButton = document.getElementById('drawing-button');
      const deleteDrawingButton = document.getElementById('delete-drawing-button');
      drawButton?.addEventListener('click', () => {
        const mode = this.draw.getMode();
        mode === 'simple_select'
          ? this.draw.changeMode('draw_polygon')
          : this.draw.changeMode('simple_select');
      });
      deleteDrawingButton?.addEventListener('click', () => {
        this.$emit('delete-draw', this.draw);
      });

      this.mapInstance?.on('zoom', (zoom: number) => {
        this.zoom = zoom;
      });

      this.mapInstance?.on('draw.create', () => {
        this.$emit('draw', this.draw);
      });
      this.mapInstance?.on('draw.update', () => {
        this.$emit('draw', this.draw);
      });
      this.mapInstance?.on('draw.selectionchange', () => {
        this.$emit('select-draw', this.draw);
      });

      this.$emit('mapLoaded', this.mapInstance);
      this.mapLoaded = true;
      this.setMarkers();
    });
  }
}
